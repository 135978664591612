<template>
    <div>
    <back-button default-to="/pendukung" v-if="$store.getters['account/accountData'].role !== 'caleg'"/>
    <add-new-dpt-pendukung-modal ref="addNewDptPendukungModal" @submitted="doFilter()"/>
    <b-card class="mb-4" no-header>
      <!-- <template #header>
        <b-row>
          <b-col cols="10">
            <h5 class="mb-0">{{$route.name}}</h5>
          </b-col>
          <b-col cols="2" class="text-right">
            <b-button-group class="add-button-group">
              <b-btn variant="primary" href="/dpts/create">Tambah Data</b-btn>
            </b-button-group>
          </b-col>
        </b-row>
      </template> -->
      <b-row>
        <b-col v-if="$store.getters['account/accountData'].role !== 'caleg'" cols="12">
          <!-- <div v-if="detail.parliamentRegion" class="mb-2">
            <p class="m-0">Silahkan pilih salah satu caleg</p>
          </div> -->
          <div class="mb-2 d-flex align-items-center">
            <p class="m-0 mr-1">{{ $store.getters['account/accountData'].role === 'staff-calon' ? 'Tambah Data' : 'Daftar'}} Pendukung </p><h4 class="m-0" v-if="detail.caleg">{{ detail.caleg.name }}</h4>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex mb-3">
            <!-- <b-button-group class="add-button-group">
              <b-btn variant="primary" to="/dpts/create">Tambah Data</b-btn>
            </b-button-group> -->
            <b-dropdown id="dropdown-1" text="Tambah Data" variant="primary">
              <b-dropdown-item @click.prevent="$refs.addNewDptPendukungModal.show()">DPT</b-dropdown-item>
              <b-dropdown-item :to="'/pendukung/create?caleg_id=' + filter.caleg_id + '&dapil_id=' + filter.dapil_id + '&parliament_region_id=' + filter.parliament_region_id">Non-DPT</b-dropdown-item>
            </b-dropdown>
            <b-button-group v-if="$store.getters['account/accountData'].role !== 'staff-calon'" class="add-button-group ml-auto">
              <b-btn variant="success" :disabled="isDownloadLoading" type="button" @click.prevent="doExport"><font-awesome-icon v-if="!isDownloadLoading" icon="file-pdf"/><font-awesome-icon v-else icon="spinner" spin/> Ekspor</b-btn>
            </b-button-group>
          </div>
        </b-col>
        <b-col v-if="$store.getters['account/accountData'].role !== 'staff-calon'"  cols="12" class="mb-4">
          <b-form @submit.prevent="doFilter">
            <b-row class="filter-row">
              <b-col :cols="$store.getters['account/accountData'].role !== 'caleg' ? 3 : 6" :md="$store.getters['account/accountData'].role !== 'caleg' ? 4 : 4">
                <form-group-input ref="filter-name" type="text" placeholder="" :need-label="true" label="nama" v-model="filter.name" id="filter-name" />
              </b-col>
              <!-- <b-col cols="3" md="1">
                <form-group-input ref="filter-dapil" type="text" placeholder="" :need-label="true" label="dapil" v-model="filter.dapil" id="filter-dapil" />
              </b-col> -->
              <b-col :cols="$store.getters['account/accountData'].role !== 'caleg' ? 3 : 6" :md="$store.getters['account/accountData'].role !== 'caleg' ? 4 : 3">
                <form-group-input ref="filter-nik" type="text" placeholder="" :need-label="true" label="NIK" v-model="filter.nik" id="filter-nik" />
              </b-col>
              <!-- <b-col cols="3" md="3">
                <form-group-input ref="filter-nkk" type="text" placeholder="" :need-label="true" label="NKK" v-model="filter.nkk" id="filter-nkk" />
              </b-col> -->
              <!-- <b-col cols="12" md="4" v-if="$store.getters['account/accountData'].role !== 'caleg'">
                <form-group-input ref="filter-caleg_id" type="enum" :options="list.caleg" placeholder="" :need-label="true" label="caleg" v-model="filter.caleg_id" id="filter-caleg_id" />
              </b-col> -->
              <b-col cols="5" md="3" v-if="$store.getters['account/accountData'].role === 'super-admin'">
                <form-group-input ref="filter-pendukung_caleg" type="enum" :options="[{
                  value: 'multiple', label: 'Ganda'
                }, {
                  value: 'single', label: 'Non-Ganda'
                }]" placeholder="" :need-label="true" label="Status Ganda" v-model="filter.pendukung_caleg" id="filter-pendukung_caleg" />
              </b-col>
              <b-col cols="12" md="4" v-else>
                <form-group-input ref="filter-koordinator_pendukung_id" type="enum" :options="list.koordinator" placeholder="" :need-label="true" label="Koordinator" v-model="filter.koordinator_pendukung_id" id="filter-koordinator_pendukung_id" />
              </b-col>
              
            </b-row>
            <b-row class="filter-row">
              <b-col cols="6" md="3" v-if="!hideCityOptions">
                <form-group-input ref="filter-city_id" type="enum" :options="list.city" placeholder="" :need-label="true" label="Kota/Kabupaten" v-model="filter.city_id" id="filter-city_id" />
              </b-col>
              <b-col cols="6" md="3">
                <form-group-input ref="filter-district_id" type="enum" :options="list.district" placeholder="" :need-label="true" label="Kecamatan" v-model="filter.district_id" id="filter-district_id" />
              </b-col>
              <b-col cols="6" md="3">
                <form-group-input ref="filter-village_id" type="enum" :options="list.village" placeholder="" :need-label="true" label="Kelurahan" v-model="filter.village_id" id="filter-village_id" />
              </b-col>
              <b-col cols="6" md="2">
                <form-group-input ref="filter-tps" type="number" min="1" placeholder="" :need-label="true" label="TPS" v-model="filter.tps" id="filter-tps" />
              </b-col>
            </b-row>
            <b-row class="filter-row">
              <b-col cols="6" md="3" v-if="$store.getters['account/accountData'].role === 'super-admin'">
                <form-group-input ref="filter-koordinator_pendukung_id" type="enum" :options="list.koordinator" placeholder="" :need-label="true" label="Koordinator" v-model="filter.koordinator_pendukung_id" id="filter-koordinator_pendukung_id" />
              </b-col>
              <b-col cols="6" md="2">
                <label class="form-label">&nbsp;</label><br>
                <b-btn type="submit" class="btn-in-form" variant="primary">
                  <font-awesome-icon icon="search"/><span>Cari</span>
                </b-btn>
                <b-btn type="button" @click.prevent="clearForm" variant="secondary" class="btn-in-form ml-2">
                  <font-awesome-icon icon="broom"/><span>Clear</span>
                </b-btn>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>

      <ul v-if="$store.getters['account/accountData'].role !== 'staff-calon'"  class="nav nav-tabs" style="border-bottom: 0px; margin-bottom: -1px;">
        <li class="nav-item">
          <a class="nav-link" :class="{active: filter.status_dpt == 1 }" aria-current="page" href="#" @click.prevent="filter.status_dpt = 1; doFilter();">DPT</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{active: filter.status_dpt == 0 }" href="#" @click.prevent="filter.status_dpt = 0; doFilter();">Non-DPT</a>
        </li>
      </ul>

      <paginated-table v-if="$store.getters['account/accountData'].role !== 'staff-calon'"  ref="dataTable" :no-actions="false" :data-url="apiUrl.dpt" :data-columns="columns" :dataParams="dataParams" :defaultDataParams="this.filter" :tableRowClassName="tableRowClassName" :noActions="$store.getters['account/accountData'].role === 'staff-calon'">
        <!-- <template slot="content_caleg" slot-scope="defaultSlotScope">
          <span style="text-transform: capitalize;">{{defaultSlotScope.colData.caleg !== null && defaultSlotScope.colData.caleg.length > 0 ? defaultSlotScope.colData.caleg.map(caleg => {return caleg.name}).join(', ') : ''}}</span>
        </template> -->
        <template slot="content_caleg" slot-scope="defaultSlotScope">
          <a style="text-transform: capitalize; display: block; color: inherit;" href="#" @click.prevent="$refs.showCalegDpt.show(defaultSlotScope.colData.id)">{{ defaultSlotScope.colData.caleg_count }}</a>
        </template>
        <template slot="content_city_id" slot-scope="defaultSlotScope">
          <span style="text-transform: capitalize;">{{defaultSlotScope.colData.city_id !== null ? defaultSlotScope.colData.city.name : ''}}</span>
        </template>
        <template slot="content_district_id" slot-scope="defaultSlotScope">
          <span style="text-transform: capitalize;">{{defaultSlotScope.colData.district_id !== null ? defaultSlotScope.colData.district.name : ''}}</span>
        </template>
        <template slot="content_village_id" slot-scope="defaultSlotScope">
          <span style="text-transform: capitalize;">{{defaultSlotScope.colData.village_id !== null ? defaultSlotScope.colData.village.name : ''}}</span>
        </template>
        <template slot="content_tps" slot-scope="defaultSlotScope">
          <span style="text-transform: capitalize;">{{defaultSlotScope.colData.tps}}</span>
        </template>
        <template slot="content_gender" slot-scope="defaultSlotScope">
          <span style="text-transform: capitalize;">{{defaultSlotScope.colData.gender == 'laki-laki' ? 'Laki-Laki' : 'Perempuan' }}</span>
        </template>
        <template slot="content_status_dpt" slot-scope="defaultSlotScope">
          <span style="text-transform: capitalize;">{{defaultSlotScope.colData.status_dpt === 1 ? 'DPT' : 'Non-DPT'}}</span>
        </template>
        <template slot="content_koordinator_pendukung_id" slot-scope="defaultSlotScope">
          <span style="text-transform: capitalize;">{{ defaultSlotScope.colData.koordinator_pendukung && defaultSlotScope.colData.koordinator_pendukung.length > 0 ? defaultSlotScope.colData.koordinator_pendukung[0].nama : '-' }}</span>
        </template>
        <template slot="actionButtons" slot-scope="defaultSlotScope">
          <b-row class="justify-content-center table-actions">
            <b-button v-if="defaultSlotScope.colData.status_dpt === 0" variant="info" size="sm" :to="'/pendukung/' + defaultSlotScope.colData.id + '/edit?caleg_id=' + filter.caleg_id + '&dapil_id=' + filter.dapil_id + '&parliament_region_id=' + filter.parliament_region_id" class="mb-0 text-center detail mr-1">
              <font-awesome-icon icon="pencil-alt"/>
            </b-button>
            <b-button variant="danger" size="sm" type="button" v-on:click.stop.prevent="doDeleteData(defaultSlotScope.colData.id)" class="mb-0 text-center delete ml-1">
              <font-awesome-icon icon="trash"/>
            </b-button>
          </b-row>
        </template>
      </paginated-table>
    </b-card>
    <show-caleg-dpt ref="showCalegDpt"/>
    </div>
  </template>
  
  <script>
  import { objectToOptions } from '@/_helpers'
  // import { objectToOptions, getYears, formatModelValue } from '@/_helpers'
  import paginatedTable from '@/components/PaginatedTable.vue'
  import formGroupInput from '@/components/FormGroupInput.vue'
  import showCalegDpt from './ShowCalegDpt.vue'
  import addNewDptPendukungModal from './AddNewDptPendukungModal.vue'
  import Pendukung from '@/models/Pendukung.js'
  import User from '@/models/User.js'
  import City from '@/models/City.js'
  import District from '@/models/District.js'
  import Village from '@/models/Village.js'
  import ParliamentRegion from '@/models/ParliamentRegion.js'
  import KoordinatorPendukung from '@/models/KoordinatorPendukung.js'
  
  const pendukungModel = new Pendukung()
  const userModel = new User()
  const cityModel = new City()
  const districtModel = new District()
  const villageModel = new Village()
  const parliamentRegionModel = new ParliamentRegion()
  const koordinatorPendukungModel = new KoordinatorPendukung()
  
  export default {
    components: {
      addNewDptPendukungModal,
      formGroupInput,
      paginatedTable,
      showCalegDpt
    },
    data () {
      return {
        isLoading: false,
        isDownloadLoading: false,
        totalPromiseAreaCount: 0,
        hideCityOptions: false,
        apiUrl: {
          dpt: pendukungModel.endpoint
        },
        columns: this.$store.getters['account/accountData'].role === 'super-admin' ? [
          {
            prop: 'nik',
            label: 'NIK',
            sortable: true,
            resizable: true,
            minWidth: 160
          },
          {
            prop: 'nkk',
            label: 'NKK',
            sortable: true,
            resizable: true,
            minWidth: 160
          },
          {
            prop: 'name',
            label: 'Nama',
            sortable: true,
            resizable: true,
            minWidth: 250
          },
          {
            prop: 'caleg',
            label: 'Dukung',
            align: 'center',
            resizable: true,
            sortable: false,
            minWidth: 100
          },
          {
            prop: 'koordinator_pendukung_id',
            label: 'Koordinator',
            sortable: true,
            resizable: true,
            minWidth: 200
          },
          {
            prop: 'phone_number',
            label: 'Kontak',
            resizable: true,
            sortable: true,
            minWidth: 120
          },
          // {
          //   align: 'center',
          //   prop: 'status_dpt',
          //   label: 'Status DPT',
          //   resizable: true,
          //   sortable: true,
          //   minWidth: 125
          // },
          // {
          //   align: 'center',
          //   prop: 'dapil',
          //   label: 'DAPIL',
          //   resizable: true,
          //   sortable: true,
          //   minWidth: 80
          // },
          {
            prop: 'city_id',
            label: 'Kabupaten',
            resizable: true,
            sortable: true,
            minWidth: 210
          },
          {
            prop: 'district_id',
            label: 'Kecamatan',
            resizable: true,
            sortable: true,
            minWidth: 210
          },
          {
            prop: 'village_id',
            label: 'Kelurahan',
            resizable: true,
            sortable: true,
            minWidth: 210
          },
          {
            align: 'center',
            prop: 'tps',
            label: 'TPS',
            resizable: true,
            sortable: true,
            minWidth: 80
          },
          {
            prop: 'hometown',
            label: 'Tempat Lahir',
            resizable: true,
            sortable: true,
            minWidth: 210
          },
          {
            align: 'center',
            prop: 'age',
            label: 'Umur',
            resizable: true,
            sortable: true,
            minWidth: 70
          },
          {
            align: 'center',
            prop: 'marriage_status',
            label: 'Status',
            resizable: true,
            sortable: true,
            minWidth: 80
          },
          {
            align: 'center',
            prop: 'gender',
            label: 'Jekel',
            resizable: true,
            sortable: true,
            minWidth: 120
          },
          {
            prop: 'address',
            label: 'Alamat',
            resizable: true,
            sortable: true,
            minWidth: 200
          },
        ] : [
          {
            prop: 'nik',
            label: 'NIK',
            sortable: true,
            resizable: true,
            minWidth: 160
          },
          {
            prop: 'nkk',
            label: 'NKK',
            sortable: true,
            resizable: true,
            minWidth: 160
          },
          {
            prop: 'name',
            label: 'Nama',
            sortable: true,
            resizable: true,
            minWidth: 250
          },
          {
            prop: 'koordinator_pendukung_id',
            label: 'Koordinator',
            sortable: true,
            resizable: true,
            minWidth: 200
          },
          {
            prop: 'phone_number',
            label: 'Kontak',
            resizable: true,
            sortable: true,
            minWidth: 120
          },
          // {
          //   align: 'center',
          //   prop: 'status_dpt',
          //   label: 'Status DPT',
          //   resizable: true,
          //   sortable: true,
          //   minWidth: 125
          // },
          {
            prop: 'city_id',
            label: 'Kabupaten',
            resizable: true,
            sortable: true,
            minWidth: 210
          },
          {
            prop: 'district_id',
            label: 'Kecamatan',
            resizable: true,
            sortable: true,
            minWidth: 210
          },
          {
            prop: 'village_id',
            label: 'Kelurahan',
            resizable: true,
            sortable: true,
            minWidth: 210
          },
          {
            align: 'center',
            prop: 'tps',
            label: 'TPS',
            resizable: true,
            sortable: true,
            minWidth: 80
          },
          {
            prop: 'hometown',
            label: 'Tempat Lahir',
            resizable: true,
            sortable: true,
            minWidth: 210
          },
          {
            align: 'center',
            prop: 'age',
            label: 'Umur',
            resizable: true,
            sortable: true,
            minWidth: 70
          },
          {
            align: 'center',
            prop: 'marriage_status',
            label: 'Status',
            resizable: true,
            sortable: true,
            minWidth: 80
          },
          {
            align: 'center',
            prop: 'gender',
            label: 'Jekel',
            resizable: true,
            sortable: true,
            minWidth: 120
          },
          {
            prop: 'address',
            label: 'Alamat',
            resizable: true,
            sortable: true,
            minWidth: 200
          },
        ],
        detail: {
          parliamentRegion: null,
          caleg: null,
        },
        list: {
          city: [],
          district: [],
          village: [],
          caleg: [],
          koordinator: [],
        },
        filter: {
          parliament_region_id: typeof this.$route.query.parliament_region_id !== 'undefined' ? this.$route.query.parliament_region_id : null,
          nik: typeof this.$route.query.nik !== 'undefined' ? this.$route.query.nik : null,
          caleg_id: typeof this.$route.query.caleg_id !== 'undefined' ? this.$route.query.caleg_id : null,
          nkk: typeof this.$route.query.nkk !== 'undefined' ? this.$route.query.nkk : null,
          name: typeof this.$route.query.name !== 'undefined' ? this.$route.query.name : null,
          dapil: typeof this.$route.query.dapil !== 'undefined' ? this.$route.query.dapil : null,
          tps: typeof this.$route.query.tps !== 'undefined' ? this.$route.query.tps : null,
          city_id: typeof this.$route.query.city_id !== 'undefined' ? this.$route.query.city_id : null,
          district_id: typeof this.$route.query.district_id !== 'undefined' ? this.$route.query.district_id : null,
          village_id: typeof this.$route.query.village_id !== 'undefined' ? this.$route.query.village_id : null,
          pendukung_caleg: typeof this.$route.query.pendukung_caleg !== 'undefined' ? this.$route.query.pendukung_caleg : null,
          dapil_id: typeof this.$route.query.dapil_id !== 'undefined' ? this.$route.query.dapil_id : null,
          with_area_detail: 1,
          dpt_pendukung: 1,
          caleg: 1,
          status_dpt: typeof this.$route.query.status_dpt !== 'undefined' ? this.$route.query.status_dpt : 1,
          tps: typeof this.$route.query.tps !== 'undefined' ? this.$route.query.tps : null,
          koordinator_pendukung_id: typeof this.$route.query.koordinator_pendukung_id !== 'undefined' ? this.$route.query.koordinator_pendukung_id : null,
        },
        dataParams: ['name', 'dapil', 'tps', 'city_id', 'district_id', 'village_id', 'with_area_detail', 'nik', 'nkk', 'dpt_pendukung', 'caleg_id', 'caleg', 'pendukung_caleg', 'parliament_region_id', 'dapil_id', 'status_dpt', 'tps', 'koordinator_pendukung_id']
      }
    },
    beforeMount () {
      this.$router.push({query: {...this.$route.query}}).catch(err => {})
    },
    async mounted () {
      await this.getParliamentRegionDetail()
      // if (this.detail.parliamentRegion.level === 3) {}
      if (this.$store.getters['account/accountData'].role !== 'staff-calon') {
        if (typeof this.$route.query.city_id !== 'undefined') {
          this.totalPromiseAreaCount++;
          this.list.district = objectToOptions(await this.getDistricts(this.$route.query.city_id))
        }
        if (typeof this.$route.query.district_id !== 'undefined') {
          this.totalPromiseAreaCount++;
          this.list.village = objectToOptions(await this.getVillages(this.$route.query.district_id)) 
        }
        if (this.$store.getters['account/accountData'].role !== 'caleg') {
          this.getCaleg()
          this.getCalegDetail()
        }
        this.getCities()
        this.getKoordinator()
      } else {
        this.getCalegDetail()
      }
    },
    watch: {
      'filter.city_id': async function (val, oldVal) {
        if (val) {
          this.list.district = objectToOptions(await this.getDistricts(val))
          if (val !== oldVal) {
            this.totalPromiseAreaCount++;
            this.filter.district_id = null
          }
        } else {
          this.filter.district_id = null
        }
      },
      'filter.district_id': async function (val, oldVal) {
        if (val) {
          this.list.village = objectToOptions(await this.getVillages(val))
          if (val !== oldVal) {
          this.totalPromiseAreaCount++;
            this.filter.village_id = null
          }
        } else {
          this.filter.village_id = null
        }
      },
      'totalPromiseAreaCount': async function (val) {
        if (val >= 4) {
          this.initEdit = false;
        }
      },
    },
    methods: {
      hundredFormat (val) {
        if (val < 10) {
          return '00' + val;
        } else if (val < 100) {
          return '0' + val;
        }
        return val;
      },
      tableRowClassName (col) {
        return this.$store.getters['account/accountData'].role !== 'caleg' ? (col.row.caleg_count > 1 ? 'table-tr-danger' : null) : null;
      },
      async getParliamentRegionDetail () {
        return new Promise(resolve => {
          parliamentRegionModel.find(this.$route.query.parliament_region_id).then(async resp => {
            this.detail.parliamentRegion = resp
            resolve('')
          })
        })
      },
      async getCaleg () {
        try {
          const calegList = await userModel.list ({
              only_has_access: 1,
              with_user_work_area: 1,
              limit: 100,
              roles: 'caleg'
          });
          let data = []
          // data[null] = 'Tidak Ada'
          calegList.data.forEach(d => {
            data[d.id] = d.name + ', Partai: ' + d.user_work_area.party.name
          })
          this.list.caleg = objectToOptions(data)
        } catch (e) {
          console.error(e)
          this.$store.dispatch('notification/error', e)
        }
      },
      async getCalegDetail () {
        if (this.$store.getters['account/accountData'].role === 'staff-calon') {
          try {
            const { data } = await this.$http.get(process.env.VUE_APP_API_URL + 'staff-calon/show-calon-detail')
            this.detail.caleg = data
          } catch (e) {
            console.error(e)
            this.$store.dispatch('notification/error', e)
          }
        } else {
          try {
            const resp = await userModel.find(this.filter.caleg_id);
            this.detail.caleg = resp
          } catch (e) {
            console.error(e)
            this.$store.dispatch('notification/error', e)
          }
        }
      },
      getCities () {
        cityModel.list({
          'registered_only': 1,
          // 'name': 'labuhan',
          'dapil_id': this.$store.getters['account/accountData'].role === 'caleg' ? this.$store.getters['account/accountData'].dapil.id : (
            typeof this.filter.dapil_id !== 'undefined' ? this.filter.dapil_id : ''
          ),
          // 'dapil_id': typeof this.filter.dapil_id !== 'undefined' ? this.filter.dapil_id : '',
          'province_id': '950b9d3a-c996-4207-97e7-5a2858981979',
          'limit': 10000
        }).then(resp => {
          let data = []
          // data[null] = 'Tidak Ada'
          resp.data.forEach(d => {
            data[d.id] = d.name
          })
          this.list.city = objectToOptions(data)
          if (resp.data.length === 1) {
            const index = this.dataParams.indexOf('city_id');
            if (index !== -1) {
              this.dataParams.splice(index, 1);
            }

            this.hideCityOptions = true
            this.filter.city_id = resp.data[0].id
          }
        })
      },
      async getDistricts (cityId) {
        return new Promise(resolve => {
          districtModel.list({
            'city_id': cityId
          }).then(resp => {
            let data = []
            // data[null] = 'Tidak Ada'
            resp.data.forEach(d => {
              data[d.id] = d.name
            })
            resolve(data);
            // this.pendukungModel.district_id.options = data
          })
        })
      },
      async getVillages (districtId) {
        return new Promise(resolve => {
          villageModel.list({
            'district_id': districtId,
          }).then(resp => {
            let data = []
            // data[null] = 'Tidak Ada'
            resp.data.forEach(d => {
              data[d.id] = d.name
            })
            resolve(data);
            // this.pendukungModel.village_id.options = data
          })
        })
      },
      clearForm () {
        this.$nextTick(() => {
          this.filter = {
            tps: null,
            koordinator_pendukung_id: null,
            with_area_detail: 1,
            caleg_id: null,
            pendukung_caleg: null,
            caleg: 1,
            dpt_pendukung: 1,
            nik: null,
            nkk: null,
            name: null,
            dapil: null,
            tps: null,
            city_id: null,
            district_id: null,
            village_id: null,
            caleg_id: this.filter.caleg_id,
            dapil_id: this.filter.dapil_id,
            parliament_region_id: this.filter.parliament_region_id,
            status_dpt: 1,
          }
          setTimeout(() => {
            this.doFilter()
          }, 100)
        })
      },
      doFilter () {
        this.$refs.dataTable.doFilter(this.filter)
      },
      doExport () {
        if (this.isDownloadLoading) {
          return false;
        }
        // this.isDownloadLoading = true;
        const filterArray = [];
        Object.keys(this.filter).forEach(key => {
          if (this.filter[key]) {
            if (key === 'city_id') {
              if (!this.hideCityOptions) {
                filterArray.push(key + "=" + this.filter[key]);
              }
            } else {
              filterArray.push(key + "=" + this.filter[key]);
            }
          }
        });
        // filterArray.push('pdf=1');
        // filterArray.push('csv=1');
        filterArray.push('xlsx=1');
        filterArray.push('access_token=' + this.$store.getters['account/tokens'].access_token);
        
        window.open(pendukungModel.getEndpoint() + "?" + filterArray.join("&"), '_blank').focus();

        
        // this.$http.get(pendukungModel.getEndpoint() + "?" + filterArray.join("&")).then(response => {
        //   const a = document.createElement("a");

        //   const blob = new Blob([response.data], {type: "octet/stream"});
        //   a.href = window.URL.createObjectURL(blob);

        //   a.download = `data pendukung ${this.$store.getters['account/accountData'].name}.xlsx`;
        //   a.click();
        //   this.isDownloadLoading = false;
        //   this.$store.dispatch('notification/success', 'Data Berhasil Diunduh.')
        // }).catch(error => {
        //   this.$store.dispatch('notification/error', 'Gagal export data, data terlalu besar.')
        //   this.isDownloadLoading = false;
        // })

        // this.$http.get(pendukungModel.getEndpoint() + "?" + filterArray.join("&"), 
        //   {
        //     responseType: 'blob'
        //   }
        // ).then((response) => {
        //   this.isDownloadLoading = false;
        //   const url = window.URL.createObjectURL(new Blob([response.data]))
        //   const link = document.createElement('a')
        //   link.href = url
        //   link.setAttribute('download', `data pendukung ${this.$store.getters['account/accountData'].name}.xlsx`)
        //   document.body.appendChild(link)
        //   link.click()
        //   this.$store.dispatch('notification/success', 'Data Berhasil Diunduh.')
        // }).catch(error => {
        //   this.isDownloadLoading = false;
        //   this.$store.dispatch('notification/error', 'Gagal export data, data terlalu besar.')
        // })

        // this.$http.get(pendukungModel.getEndpoint() + "?" + filterArray.join("&")).then(response => {
        //   const a = document.createElement("a");
  
        //   const blob = new Blob([response.data], {type: "octet/stream"});
        //   a.href = window.URL.createObjectURL(blob);
  
        //   a.download = "data_pendukung.csv";
        //   a.click();
        //   this.isLoading = false;
        // }).catch(error => {
        //   this.$store.dispatch('notification/error', error)
        //   this.isLoading = false;
        // })
      },
      doDeleteData (id) {
        this.$swal.fire({
          title: 'Anda Yakin?',
          text: 'Data tidak akan dapat dikembalikan lagi!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
          // confirmButtonClass: 'btn btn-danger mr-3',
          // cancelButtonClass: 'btn btn-default',
          // buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.$http.post(this.apiUrl.dpt + '/' + id, {
              _method: 'DELETE',
              caleg_id: this.filter.caleg_id
            }).then(response => {
              this.$store.dispatch('notification/success', 'Berhasil menghapus.')
              this.doFilter()
            }).catch(error => {
              this.$store.dispatch('notification/error', 'Gagal menghapus data.')
              console.log(error)
            })
          }
        })
      },
      async getKoordinator () {
        const koordinatorList = await koordinatorPendukungModel.list({
          // nama: search,
          caleg_id: this.filter.caleg_id,
          limit: 1000000
        });
        const options = []
        koordinatorList.data.forEach(user => {
          options.push({
            value: user.id,
            label: user.nama
          });
        });
        this.list.koordinator = options
      },
    }
  }
  </script>